import React, { useCallback, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { CssBaseline, useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import MainHeader from '../organisms/MainHeader/index'
import SideNav from '../organisms/SideNav'
import { useAppContext, useSetAppContext } from '../../redux/slices/appContext'
import { MAIN_HEADER_HEIGHT } from '../../constants'
import { useHomeViewPath, useShowHome } from '../../redux/slices/appConfig'
import PrintViewModestyCurtain from '../molecules/PrintViewModestyCurtain'
import CommentPanel from '../organisms/Comments/CommentPanel'
import { HeaderScrollContextProvider } from '../organisms/MainHeader/HeaderScrollContext'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    zIndex: 1
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%'
  },
  mainContent: {
    height: '100vh',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      marginLeft: 0,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    }
  },
  mainContentShift: {
    [theme.breakpoints.up('lg')]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    }
  },
  content: {
    flexGrow: 1,
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: ({ presentationMode }) =>
      presentationMode ? '100vh' : `calc(100vh - ${MAIN_HEADER_HEIGHT}px)`
  }
}))

function Layout ({ children }) {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const sidebarMin = useMediaQuery(theme.breakpoints.up('sidebarMin'))
  const sidebarMax = useMediaQuery(theme.breakpoints.up('sidebarMax'))
  const setAppContext = useSetAppContext()
  const appContext = useAppContext()
  const { isSidebarOpen, isAdvisor, overContentOnDesktop, presentationMode } = appContext
  const rightSidebarFits = sidebarMax || (sidebarMin && !isSidebarOpen)
  const classes = useStyles({ presentationMode })
  const location = useLocation()
  const { pathname } = location
  const homeViewPath = useHomeViewPath()
  const showHome = useShowHome()
  const isHomeView = useMemo(() => pathname === '/' || pathname === homeViewPath, [pathname, homeViewPath])
  const isPreferencesPath = useMemo(() => pathname === '/preferences', [pathname])

  /* TODO: Only effect navigation changes from target routes */
  useEffect(() => {
    if (mdDown || isHomeView || isPreferencesPath) {
      setAppContext({ isSidebarOpen: false })
    }
  }, [mdDown, isHomeView, setAppContext, isPreferencesPath])

  useEffect(() => {
    setAppContext({ rightSidebarFits })
  }, [rightSidebarFits, setAppContext])

  const toggleSideNav = useCallback(() => {
    setAppContext({ isSidebarOpen: !isSidebarOpen })
  }, [setAppContext, isSidebarOpen])

  useEffect(() => {
    const paths = ['admin', 'clients']

    for (let i = 0; i < paths.length; i++) {
      const path = paths[i]
      if (pathname.includes(path)) {
        setAppContext({ isSidebarOpen: false })
        break
      }
    }
  }, [pathname, setAppContext])

  return (
    <HeaderScrollContextProvider>
      <PrintViewModestyCurtain />
      <div className={classes.root}>
        <CssBaseline />
        <div className={classes.container}>
          {isAdvisor && (
            <SideNav
              isAdvisor={isAdvisor}
              showHome={!!showHome}
              overContentOnDesktop={isHomeView || overContentOnDesktop}
              sideNavOpen={isSidebarOpen}
              toggleSideNav={toggleSideNav}
            />
          )}
          <div
            className={clsx(classes.mainContent, {
              [classes.mainContentShift]: !isSidebarOpen && !overContentOnDesktop
            })}
          >
            {!presentationMode && (
              <MainHeader
                overContentOnDesktop={isHomeView || overContentOnDesktop}
                sideNavOpen={isSidebarOpen}
                toggleSideNav={toggleSideNav}
              />
            )}
            <div className={classes.content}>{children}</div>
          </div>
        </div>
        <CommentPanel />
      </div>
    </HeaderScrollContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node
}

Layout.defaultProps = {
  children: null
}

export default Layout
