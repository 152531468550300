import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { ICON_NAMES, KEYS } from '../../constants'
import { useDebounce } from '../../hooks/useDebounce'
import Icon from '../atoms/Icon'

const useStyles = makeStyles((theme) => ({
  searchBar: {
    height: '100%',
    minWidth: '30rem',
    width: '100%',
    border: `1px solid ${theme.palette.gray.dark}`,
    borderRadius: '4px',
    padding: '0 1rem 0 .7rem',
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > input': {
      lineHeight: '2rem',
      backgroundColor: 'transparent',
      fontFamily: theme.typography.fontFamily,
      width: '100%',
      border: 'none',
      fontSize: '1rem',
      borderTopRightRadius: '1.5rem',
      borderBottomRightRadius: '1.5rem',
      '&:focus': {
        outline: 'none'
      },
      '&:-webkit-input-placeholder': {
        opacity: '0.2',
        fontWeight: 'bold'
      }
    },
    '& .__icon': {
      marginRight: '5px'
    }
  }
}))

const DebouncedInput = forwardRef(({
  className,
  onChange = noop,
  placeholder,
  delay = 1000,
  iconName = ICON_NAMES.search,
  defaultValue,
  style,
  disabled
}, ref) => {
  const classes = useStyles()

  const [localValue, setLocalValue] = useState({ value: defaultValue })
  const [debouncedValue] = useDebounce(localValue, delay)

  useImperativeHandle(ref, () => ({
    clear: () => setLocalValue({ value: '', immediate: false }),
    reset: () => setLocalValue({ value: '', immediate: true }),
    value: debouncedValue
  }), [debouncedValue])

  const change = useCallback((e) => {
    setLocalValue && setLocalValue({ value: e.target.value, immediate: false })
  }, [setLocalValue])

  useEffect(() => {
    setTimeout(() => {
      onChange(debouncedValue)
    }, 0)
  }, [debouncedValue, onChange])

  const inputRef = useRef(null)

  const onKeyPress = useCallback(e => {
    if (e.target !== inputRef.current) return

    if (e.which === KEYS.ESC && inputRef.current?.value?.length > 0) {
      e.stopPropagation()
      e.preventDefault()
      setLocalValue && setLocalValue({ value: '', immediate: true })
      return
    }
    if (e.which === KEYS.ENTER) {
      e.stopPropagation()
      e.preventDefault()
      setLocalValue && setLocalValue({ value: e.target.value, immediate: true })
      // eslint-disable-next-line no-useless-return
      return
    }
  }, [setLocalValue])

  useEffect(() => {
    const kp = onKeyPress
    window.addEventListener('keydown', kp, true)
    return () => {
      window.removeEventListener('keydown', kp)
    }
  }, [onKeyPress])

  return (
    <div className={clsx(classes.searchBar, className)} style={style}>
      <Icon additionalClasses='__icon' name={iconName} customSize='1.5rem' />
      <input
        disabled={disabled}
        ref={inputRef}
        value={localValue.value}
        onChange={change}
        placeholder={placeholder}
      />
    </div>
  )
})

DebouncedInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  delay: PropTypes.number,
  iconName: PropTypes.string,
  style: PropTypes.object,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool
}

DebouncedInput.defaultProps = {
  delay: 1000,
  iconName: ICON_NAMES.search,
  defaultValue: ''
}

export default DebouncedInput
