const childKeyToParentMap = {
  accountId: 'levelId',
  accountFee: 'totalFee',
  accountName: 'levelName',
  accountBalanceTotal: 'originalBalance',
  accountNumber: 'accountNumber',
  nonBillable: 'nonBillable',
  feeOverrideLongName: 'feeSchedules',
  adjustmentValue: 'adjustmentValue',
  accountPreAdjustmentFee: 'preAdjustmentFee'
}
const childOnlyKeys = ['feeOverrideLongName', 'feeOverrideShortName', 'nonBillable', 'excludeFromBilling', 'feeAdjustmentId']
const parentGroupingKey = 'billingRunRecordId'
const disabledKeys = ['nonBillable', 'excludeFromBilling']
const statusKeys = ['nonBillable', 'stale', 'duplicate']

export const normalizeBillingRun = (columns, data, warnings = {}) => {
  const { details, total: _total } = data ?? {}
  const total = _total ?? details?.length ?? 0

  const uniqueColumnAccessors = [...new Set(
    columns.flatMap(column => column.columns ? column.columns.map(col => col.accessor) : column.accessor))
  ]

  const billingRunRecords = details.map(billingRunRecord => ({
    ...billingRunRecord,
    ...(warnings[billingRunRecord.accountId] ?? {})
  }))

  return {
    total,
    details: billingRunRecords.reduce((acc, record) => {
      const existingIndex = acc.findIndex(r => r[parentGroupingKey] === record[parentGroupingKey])
      const subRow = {
        ...record,
        id: record[parentGroupingKey],
        statuses: statusKeys.filter(key => !!record[key]),
        disabled: disabledKeys.some(key => !!record[key])
      }
      Object.keys(childKeyToParentMap).forEach(childKey => {
        const newKey = childKeyToParentMap[childKey]
        subRow[newKey] = record[childKey]
      })

      if (existingIndex === -1) {
        const parentData = uniqueColumnAccessors.filter(acc => !childOnlyKeys.includes(acc)).reduce((parent, accessor) => {
          parent[accessor] = record[accessor]
          return parent
        }, {
          id: record[parentGroupingKey],
          [parentGroupingKey]: record[parentGroupingKey],
          statuses: statusKeys.filter(key => !!record[key])
        })

        if (subRow.adjustmentValue !== null) {
          parentData.statuses.push('hasFeeAdjustments')
        }

        acc.push({
          ...parentData,
          subRows: [subRow]
        })
      } else {
        acc[existingIndex].subRows.push(subRow)
        acc[existingIndex].subRows = acc[existingIndex].subRows.sort((a, b) => a.disabled - b.disabled)
        for (const status of statusKeys.filter(key => !!record[key])) {
          if (!acc[existingIndex].statuses.includes(status)) {
            acc[existingIndex].statuses.push(status)
          }
        }
        if (subRow.adjustmentValue !== null && !acc[existingIndex].statuses.includes('hasFeeAdjustment')) {
          acc[existingIndex].statuses.push('hasFeeAdjustments')
        }
      }
      return acc
    }, [])
  }
}
