import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useBillingRun, useBillingRunDetails } from '../../../../../../api/billing'
import SearchBarInput from '../../../../../molecules/SearchBar/SearchBarInput'
import { RUN_STATUS } from '../../runBilling/helpers'
import { ADMIN_ROUTES } from '../../../../../../constants'
import BillingRunDetailsTable from '../billingRunDetailsTable'
import { useColumns } from '../hooks'
import TableFiltersContainer from '../billingRunDetailsTable/TableFiltersContainer'
import { useCheckPolicy } from '../../../../../../hooks'
import { BILLING } from '../../../../../../policies/admin'
import useDebouncedValue from '../../../../../../hooks/useDebounceValue'
import FeeAdjustmentEditModal from './modals/FeeAdjustmentEditModal'
import FeeAdjustmentDeleteConfirmModal from './modals/FeeAdjustmentDeleteConfirmModal'
import { normalizeBillingRun } from './utils/normalizeBillingRun'
import useStyles from './styles'
import StatusFilter from './filters/StatusFilter'
import { useUpdateBillingRunRecordId } from './utils/useUpdateBillingRunRecordId'
import { defaultColumnConfig, presetColumnsMap } from './utils/columnConfig'

const BillingRunDetailsPage = ({
  billingRunId,
  columnsConfig: _columnsConfig = defaultColumnConfig
}) => {
  const history = useHistory()
  const canViewDetails = useCheckPolicy(BILLING.viewDetails)
  if (!canViewDetails) {
    history.replace(ADMIN_ROUTES.BILLING)
  }

  const { data: billingRun, isFetching: isFetchingBillingRun, isLoading: isLoadingBillingRun } = useBillingRun(billingRunId)

  const isValidBillingRun = useMemo(() => !!billingRun?.billingRunId && [RUN_STATUS.IN_REVIEW, RUN_STATUS.MARK_AS_FINAL].includes(billingRun?.status), [billingRun])

  useEffect(() => {
    if (!isFetchingBillingRun && !isValidBillingRun) {
      history.replace(ADMIN_ROUTES.BILLING_RUN_DETAILS)
    }
  }, [isValidBillingRun, history, isFetchingBillingRun])

  const classes = useStyles()
  const [searchText, setSearchText] = useState('')
  const debouncedSearchText = useDebouncedValue(searchText, 500)
  const [statusFilters, setStatusFilters] = useState([])
  const [editingFeeAdjustmentParams, setEditingFeeAdjustmentParams] = useState(null)
  const [confirmDeleteParams, setConfirmDeleteParams] = useState(null)
  const [updatingBillingRunRecordIds, setUpdatingBillingRunRecordIds] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)

  const onSetShowFeeAdjustmentModal = useCallback(({ billingRunRecordId, accountId, accountNumber, accountName, feeAdjustmentId }) => {
    setEditingFeeAdjustmentParams({ billingRunRecordId, accountId, accountNumber, accountName, feeAdjustmentId })
  }, [])

  const onTriggerDelete = useCallback((row) => {
    const { accountId, billingRunRecordId, accountName, accountValue } = row
    setConfirmDeleteParams({
      accountId, billingRunRecordId, accountName, accountValue
    })
  }, [])

  const handleStatusFilterChange = useCallback((value) => {
    setStatusFilters(value)
    setPage(1)
  }, [])

  const handleSearchTextChange = useCallback((value) => {
    setSearchText(value)
    setPage(1)
  }, [])

  const columnsConfig = useColumns({
    columnsConfig: _columnsConfig,
    handlers: { onSetShowFeeAdjustmentModal, onTriggerDelete },
    presetColumns: presetColumnsMap
  })

  const query = useMemo(
    () => {
      return {
        billingRunId,
        take: pageSize,
        skip: (page - 1) * pageSize,
        includes: {
          feeScheduleOverrides: true,
          feeSchedules: true,
          feeAdjustments: true,
          total: true
        },
        filters: {
          ...(debouncedSearchText?.length ? { levelName: [{ op: 'contains', value: searchText }] } : {}),
          ...(statusFilters?.length ? statusFilters.reduce((acc, f) => ({ [f]: [{ op: 'exists', value: '' }] }), {}) : {})
        }
      }
    }, [billingRunId, debouncedSearchText?.length, page, pageSize, searchText, statusFilters]
  )

  const {
    data = [],
    isFetching
  } = useBillingRunDetails(query, {
    enabled: !isFetchingBillingRun && isValidBillingRun,
    mapper: (data) => normalizeBillingRun(columnsConfig.columns, data, billingRun.warnings ?? {})
  })

  const updateBillingRunRecordId = useUpdateBillingRunRecordId({ query, columnsConfig, billingRun })

  const onSubmitSuccess = useCallback(async (billingRunRecordId) => {
    setEditingFeeAdjustmentParams(null)
    setUpdatingBillingRunRecordIds((prev) => [...new Set([...prev, billingRunRecordId])])
    await updateBillingRunRecordId.mutateAsync({ billingRunRecordId, data })
    setUpdatingBillingRunRecordIds((prev) => prev.filter((id) => id !== billingRunRecordId))
  }, [data, updateBillingRunRecordId])

  return (
    <>
      <FeeAdjustmentEditModal
        title={`Fee Schedule for ${editingFeeAdjustmentParams?.accountName}`}
        onClose={() => setEditingFeeAdjustmentParams(null)}
        onSuccess={onSubmitSuccess}
        feeAdjustmentId={editingFeeAdjustmentParams?.feeAdjustmentId}
        billingRunRecordId={editingFeeAdjustmentParams?.billingRunRecordId}
        accountId={editingFeeAdjustmentParams?.accountId}
      />

      <FeeAdjustmentDeleteConfirmModal
        title='Delete this adjustment?'
        subtitle={`Are you sure you want to delete the fee adjustment for ${confirmDeleteParams?.accountName}?`}
        onClose={() => setConfirmDeleteParams(null)}
        onSuccess={onSubmitSuccess}
        billingRunRecordId={confirmDeleteParams?.billingRunRecordId}
        accountId={confirmDeleteParams?.accountId}
      />

      <div className={classes.container}>
        <TableFiltersContainer>
          <div>
            <SearchBarInput
              name='searchBillingRunRecords'
              value={searchText}
              onChange={handleSearchTextChange}
              onClear={() => setSearchText('')}
              placeholder='Search by client name'
            />
          </div>
          <div>
            <StatusFilter
              onChange={handleStatusFilterChange}
              value={statusFilters}
            />
          </div>
        </TableFiltersContainer>

        <BillingRunDetailsTable
          loading={isFetching || isLoadingBillingRun}
          data={data ?? {}}
          columns={columnsConfig.columns}
          hiddenColumns={columnsConfig.hiddenColumns}
          defaultSort={columnsConfig.defaultSort}
          statusFilters={statusFilters}
          updatingIds={updatingBillingRunRecordIds}
          onPageSizeChange={setPageSize}
          onPageChange={setPage}
          currentPage={page}
          pageSize={pageSize}
          defaultPageSize={20}
        />
      </div>
    </>
  )
}

BillingRunDetailsPage.propTypes = {
  billingRunId: PropTypes.number,
  columnsConfig: PropTypes.objectOf(PropTypes.shape({
    columns: PropTypes.array,
    defaultSort: PropTypes.array
  }))
}

BillingRunDetailsPage.defaultProps = {
}

export default BillingRunDetailsPage
