import React from 'react'
import FeeAdjustmentCell from '../cells/FeeAdjustmentCell'
import LevelNameCell from '../cells/LevelNameCell'
import NonBillableCell from '../cells/NonBillableCell'

export const defaultColumnConfig = {
  columns: [
    'levelId',
    'levelName',
    'originalBalance',
    'feeSchedules',
    'totalFee',
    'preAdjustmentFee',
    'adjustmentValue'
  ],
  hiddenColumns: ['levelId'],
  defaultSort: [{ id: 'levelId', desc: false }]
}

export const presetColumnsMap = new Map([
  ['levelId', { Header: 'Level Id', accessor: 'levelId' }],
  ['levelName', { Header: 'Level Name', width: 300, accessor: 'levelName', Cell: LevelNameCell }],
  ['originalBalance', { Header: 'Original Balance', accessor: 'originalBalance', format: '$0,000.00' }],
  ['totalFee', { Header: 'Current Fee', accessor: 'totalFee', format: '$0,000[.]00' }],
  ['feeSchedules', { Header: 'Fee Schedule / Override', accessor: 'feeSchedules', Cell: ({ value }) => Array.isArray(value) ? value.join(', ') : value }],
  ['nonBillable', { Header: 'Non Billable?', accessor: 'nonBillable', Cell: NonBillableCell }],
  ['preAdjustmentFee', { Header: 'Original Fee', accessor: 'preAdjustmentFee', format: '$0,000[.]00' }],
  ['adjustmentValue', { Header: 'Fee Adjustments', accessor: 'adjustmentValue', width: 180, Cell: (props, handlers) => (<FeeAdjustmentCell {...props} {...handlers} />) }]
])
